import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-public-topbar',
  templateUrl: './public-topbar.component.html',
  styleUrls: ['./public-topbar.component.scss'],
  standalone: false
})
export class PublicTopbarComponent {

  @Input() public platform = '';

}
