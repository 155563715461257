import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '_environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://4132ed5d3ab84c518ea56a7d76f65a57@o268277.ingest.sentry.io/5355841',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.getCurrentScope().setTag('app.environment', environment.environment);
Sentry.getCurrentScope().setTag('app.name', 'PRE-ORD');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  public handleError(error: { originalError: unknown; }): void {
    Sentry.captureException(error.originalError || error);
  }
}
