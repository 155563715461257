import { Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false
})
export class AppComponent {
  public readonly title = 'PRE-ORD';
  public readonly appVersion: number;

  public constructor() {
    this.appVersion = environment.version!;
  }
}
